import $axios from "@/plugins/axios";

const menuTemplatesService = {
  async getMenuTemplateCategories(){
      try{
        const { data } = await $axios.get(`menu-template-categories`);
        return data;
      }catch (e){
        return e;
      }
  },
  async getMenuTemplateCategory(category_id){
    try{
      const {data} = await $axios.get(`menu-template-category/${category_id}`);
      return data;
    }catch (e){
      return e;
    }
  },
  async getMenuTemplatesByCategory(category_id){
    try{
      const {data}  = await $axios.get(`menu-templates/${category_id}`);
      return data;
    }catch (e){
      return e;
    }
  },
  async getMenuTemplate(template_id){
    try{
      const {data} = await $axios.get(`menu-template/${template_id}`);
      return data;
    }catch (e){
      return e;
    }
  }
}
export default menuTemplatesService
