<template>
  <v-dialog :fullscreen="$store.state.isMobile"
                    max-width="700px"
                    height="400px"
                    v-model="show"
                    @click:outside="closeDialogTemplate"
>
  <div class="popup__content popup__content_email" :style="`justify-content:${$store.state.isMobile?'flex-start':'center'};`" style="padding-left:20px;padding-right:20px;">
    <v-btn class="popup__close"
           small icon @click="closeDialogTemplate">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <div>
      <div class="control">
        <label style="display:block;margin-bottom:15px;font-weight:bold;font-size:20px;" class="label">Templates</label>
        <div style="display: flex;margin-bottom: 15px;" :style="`flex-direction:${$store.state.isMobile?'column':'row'};align-items:${$store.state.isMobile?'flex-start':'center'};`">
          <label for="" class="label" style="display: block;margin-right: 15px;" :style="`margin-bottom:${$store.state.isMobile?'15px':'0'}`">Select template category:</label>
          <v-select :disabled="loading" :loading="loading" return-object :items="categories" item-text="name" item-value="id" hide-details v-model="selected_category" placeholder="Choose template category" outlined dense></v-select>
        </div>
        <div>
          <div style="display:flex;flex-direction:column;overflow-y:auto;">
            <div style="margin-bottom:15px;" v-for="template in templates" :key="template.id">
              <div>
                <swiper
                    :direction="'horizontal'"
                    :pagination="{ clickable: true }" style="position: relative;float:left;width:150px;overflow: hidden;" :options="swiperImageOptions" ref="mySwiperImage">
                  <swiper-slide v-for="img in template.images" :key="img">
                    <div style="width:150px;border: 1px solid #ccc;border-radius: 15px;">
                      <img :src="img" alt="" style="width:150px;height:200px;border-radius: 15px;">
                    </div>
                  </swiper-slide>
                  <div style="width:200px" class="swiper-pagination" slot="pagination" />
                </swiper>
                <div style="display: flex;padding:15px;flex-direction: column">
                  <div style="margin-bottom: 15px;font-weight: bold;">
                    {{template.name}}
                  </div>
                  <div>
                    {{template.description}}
                  </div>
                  <div style="display: flex;align-items: center;" :style="`justify-content:${$store.state.isMobile?'flex-start':'flex-end'};margin-top:${$store.state.isMobile?'15px':'0'}`">
                    <v-btn @click="installContent(template)" small outlined color="primary">
                      Install
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</v-dialog>
</template>
<script lang="js">
import "swiper/swiper-bundle.css";
import "swiper/modules/pagination/pagination.min.css";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import {defaultFormMenuItem} from "@/store/landing";
import menuTemplatesService from "@/services/menuTemplates.service";
SwiperCore.use([Pagination]);
import {mapState} from 'vuex'
export default{
  props:{
    show:{
      type:Boolean,
      default:false
    }
  },
  computed:{
    ...mapState(['landing']),
    categories(){
      return ['All',...this.template_categories]
    },
    templates(){
      if(this.selected_category!=='All'){
        return [this.blank_template,...this.selected_category.templates];
      }else{
        return this.template_categories.reduce((acc,item) => {
          acc = [...acc,...item.templates]
          return acc;
        },[this.blank_template]);
      }
    }
  },
  data(){
    return{
      dialog_template:true,
      template_categories:[],
      selected_category:"All",
      loading:false,
      swiperOptions:{
        slidesPerView: 'auto',
        spaceBetween: 10,
      },
      swiperImageOptions:{
        slidesPerView: 1,
        spaceBetween: 10,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      },
      blank_template:{
        images:["https://api.guesteco.com/storage/media/13002/c211d1b3-6523-43fa-9149-be86a04464d2.webp"],
        name:"About the Place",
        description:"About the place information",
        type:4,
        id:0,
        content: {
          content:{
            listing: [
              {
                title: "House Rules",
                constructor_table_title:"Rules and policies",
                hide: true,
                content: [
                  {
                    constructor_main_img:"Add an images o help explain",
                    constructor_img:"Add an image o help explain",
                    constructor_name:"Helper for name",
                    constructor_small_description:"Helper for small description",
                    constructor_video_link:"Add a video o help explain",
                    constructor_full_description:"Helper for full description",
                    img: [],
                    video_link: null,
                    name: "Pets",
                    descr: "",
                    main_img: "",
                    preview: false,
                    full_descr: "Provide the rules about pets",
                    price: "100",
                    show_price: true,
                    show_descr: true,
                    hide: true,
                    name_color: "#000000",
                    lining_padding: 15,
                    position: "center center",
                    background_color:
                        "rgba(255,255,255,1)",
                    descr_color: "#000000",
                    show_name_color: false,
                    show_descr_color: false,
                    id: 1,
                    form: JSON.parse(
                        JSON.stringify(
                            defaultFormMenuItem
                        )
                    ),
                  },
                  {
                    constructor_main_img:"Add an images o help explain",
                    constructor_img:"Add an image o help explain",
                    constructor_name:"Helper for name",
                    constructor_small_description:"Helper for small description",
                    constructor_video_link:"Add a video o help explain",
                    constructor_full_description:"Helper for full description",
                    img: [],
                    video_link: null,
                    name: "Smoking",
                    descr: "",
                    main_img: "",
                    preview: false,
                    full_descr: "Provide the rules about smoking",
                    price: "100",
                    show_price: true,
                    show_descr: true,
                    hide: true,
                    name_color: "#000000",
                    lining_padding: 15,
                    position: "center center",
                    background_color:
                        "rgba(255,255,255,1)",
                    descr_color: "#000000",
                    show_name_color: false,
                    show_descr_color: false,
                    id: 2,
                    form: JSON.parse(
                        JSON.stringify(
                            defaultFormMenuItem
                        )
                    ),
                  },
                  {
                    constructor_main_img:"Add an images o help explain",
                    constructor_img:"Add an image o help explain",
                    constructor_name:"Helper for name",
                    constructor_small_description:"Helper for small description",
                    constructor_video_link:"Add a video o help explain",
                    constructor_full_description:"Helper for full description",
                    img: [],
                    video_link: null,
                    name: "Additional Guests",
                    descr: "",
                    main_img: "",
                    preview: false,
                    full_descr: "Provide the rules about additional guests",
                    price: "100",
                    show_price: true,
                    show_descr: true,
                    hide: true,
                    name_color: "#000000",
                    lining_padding: 15,
                    position: "center center",
                    background_color:
                        "rgba(255,255,255,1)",
                    descr_color: "#000000",
                    show_name_color: false,
                    show_descr_color: false,
                    id: 3,
                    form: JSON.parse(
                        JSON.stringify(
                            defaultFormMenuItem
                        )
                    ),
                  },
                  {
                    constructor_main_img:"Add an images o help explain",
                    constructor_img:"Add an image o help explain",
                    constructor_name:"Helper for name",
                    constructor_small_description:"Helper for small description",
                    constructor_video_link:"Add a video o help explain",
                    constructor_full_description:"Helper for full description",
                    img: [],
                    video_link: null,
                    name: "Quiet Hours",
                    descr: "",
                    main_img: "",
                    preview: false,
                    full_descr: "Provide the rules about quiet hours",
                    price: "100",
                    show_price: true,
                    show_descr: true,
                    hide: true,
                    name_color: "#000000",
                    lining_padding: 15,
                    position: "center center",
                    background_color:
                        "rgba(255,255,255,1)",
                    descr_color: "#000000",
                    show_name_color: false,
                    show_descr_color: false,
                    id: 4,
                    form: JSON.parse(
                        JSON.stringify(
                            defaultFormMenuItem
                        )
                    ),
                  },
                ],
              },
              {
                title: "Connecting to the internet",
                constructor_table_title:"Get your guests connected",
                hide: true,
                content: [
                ],
              },
              {
                title: "Setting the Temperature",
                constructor_table_title:"Keeping your guests comfortable",
                hide: true,
                content: [
                ],
              },
            ],
          }
        },
      }
    }
  },
  components:{
    Swiper, SwiperSlide
  },
  methods:{
    installContent(template){
      this.$store.state.menu.selectedMenuItem.type = template.type;
      this.$store.state.menu.selectedMenuItem.content[this.landing.current_lang][template.type-1].content = JSON.parse(JSON.stringify(template.content.content));
      this.forceRerender();
      this.$emit('closeModal');
    },
    forceRerender() {
      // Removing my-component from the DOM
      this.$store.state.renderComponent = false;

      this.$nextTick(() => {
        // Adding the component back in
        this.$store.state.renderComponent = true;
      });
    },
    closeDialogTemplate(){
      this.$emit('closeModal');
    }
  },
  async created(){
    this.template_categories = await menuTemplatesService.getMenuTemplateCategories();
  }
}
</script>


<style scoped lang="scss">
</style>
